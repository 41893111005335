import React from "react"
import { Menu, Button, Icon } from "semantic-ui-react"

export default function ReloadMenu(props) {
  return (
    <Menu.Item>
      <Button icon size="tiny" disabled={props.isLoading} onClick={props.onClick}>
        <Icon name="refresh" loading={props.isLoading} />
      </Button>
    </Menu.Item>
  )
}
