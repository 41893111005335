import React from "react"
import { Menu, Dropdown } from "semantic-ui-react"
import styles from "./Menu.module.css"

export default function PlayerMenu(props) {
  return (
    <Menu.Item>
      <Dropdown
        item
        text={props.playerCount === 0 ? "Any" : props.playerCount.toString()}
        icon="users"
        button
        className={styles.button}
      >
        <Dropdown.Menu>
          <Dropdown.Item text="Any" onClick={() => props.setPlayerCount(0)} />
          <Dropdown.Item text="1 player" onClick={() => props.setPlayerCount(1)} />
          <Dropdown.Item text="2 players" onClick={() => props.setPlayerCount(2)} />
          <Dropdown.Item text="3 players" onClick={() => props.setPlayerCount(3)} />
          <Dropdown.Item text="4 players" onClick={() => props.setPlayerCount(4)} />
          <Dropdown.Item text="5 players" onClick={() => props.setPlayerCount(5)} />
          <Dropdown.Item text="6 players" onClick={() => props.setPlayerCount(6)} />
          <Dropdown.Item text="7 players" onClick={() => props.setPlayerCount(7)} />
          <Dropdown.Item text="8 players" onClick={() => props.setPlayerCount(8)} />
          <Dropdown.Item text="9 players" onClick={() => props.setPlayerCount(9)} />
          <Dropdown.Item text="10 players" onClick={() => props.setPlayerCount(10)} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}
