import "semantic-ui-css/semantic.min.css"
import "./index.css"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import registerServiceWorker from "./registerServiceWorker"
import { configure } from "mobx"
import ReactGA from "react-ga"
import { BrowserRouter } from "react-router-dom"

configure({
  enforceActions: "always",
})

const trackingId = "UA-64707608-3"
ReactGA.initialize(trackingId)

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)
registerServiceWorker()
