import React from "react"
import { Menu, Dropdown } from "semantic-ui-react"
import styles from "./Menu.module.css"

export default function PlaytimeMenu(props) {
  return (
    <Menu.Item>
      <Dropdown
        item
        text={props.maxPlaytime === 0 ? "Any" : props.maxPlaytime.toString()}
        icon="clock"
        button
        className={styles.button}
      >
        <Dropdown.Menu>
          <Dropdown.Item text="Any" onClick={() => props.setMaxPlaytime(0)} />
          <Dropdown.Item text="10m" onClick={() => props.setMaxPlaytime(10)} />
          <Dropdown.Item text="20m" onClick={() => props.setMaxPlaytime(20)} />
          <Dropdown.Item text="30m" onClick={() => props.setMaxPlaytime(30)} />
          <Dropdown.Item text="60m" onClick={() => props.setMaxPlaytime(60)} />
          <Dropdown.Item text="90m" onClick={() => props.setMaxPlaytime(90)} />
          <Dropdown.Item text="120m" onClick={() => props.setMaxPlaytime(120)} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}
