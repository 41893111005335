import React, { Component } from "react"
import GameItem from "components/GameItem"
import { Item, Container, Menu } from "semantic-ui-react"
import { observable, action, computed } from "mobx"
import { observer } from "mobx-react"
import GameStore from "stores/GameStore"
import styles from "components/Collection.module.css"
import { shuffle } from "lib/Utils"
import Search from "./Search"
import ReloadMenu from "./menus/ReloadMenu"
import PlayerMenu from "./menus/PlayerMenu"
import PlaytimeMenu from "./menus/PlaytimeMenu"
import SortMenu from "./menus/SortMenu"
import HomeMenu from "./menus/HomeMenu"
@observer
class Collection extends Component {
  @observable filter = ""
  GameStore = null
  @observable playerCount = 0
  @observable maxPlaytime = 0
  @observable sort = "Name"

  constructor(props) {
    super(props)
    this.GameStore = new GameStore()
    this.load()
  }

  componentDidMount() {
    document.title = `BGG Collection Browser: ${this.props.match.params.username}`
  }

  gamesListItems(games) {
    let listOfGames = games

    if (this.filter.length > 0) {
      listOfGames = this.GameStore.fuse.search(this.filter).map((result) => result.item)
    }

    listOfGames = listOfGames
      .filter((game) => {
        if (this.playerCount === 0) return true
        if (game.minPlayers === 0 || game.maxPlayers === 0) return true // unknown
        return this.playerCount >= game.minPlayers && this.playerCount <= game.maxPlayers
      })
      .filter((game) => {
        if (this.maxPlaytime === 0) return true
        if (game.maxPlaytime === 0) return true // unknown
        return this.maxPlaytime >= game.maxPlaytime
      })

    if (this.sort === "Random") listOfGames = shuffle(listOfGames)
    if (this.sort === "Rating") {
      listOfGames.sort((a, b) => {
        // sort by rating, then name
        if (a.rating === b.rating) {
          return a.name - b.name
        }
        return b.rating - a.rating
      })
    }
    if (this.sort === "Rank") {
      listOfGames.sort((a, b) => {
        return a.rank - b.rank
      })
    }
    if (this.sort === "Name") {
      listOfGames.sort((a, b) => {
        return a.name - b.name
      })
    }

    return listOfGames.map((game) => {
      return <GameItem game={game} key={`${game.hash}`} />
    })
  }

  @action.bound
  load(force = false) {
    this.GameStore.loadGamesForUsername(this.props.match.params.username, force)
  }

  @computed get games() {
    return this.GameStore.games || []
  }

  @action.bound
  setPlayerCount(count) {
    this.playerCount = count
  }

  @action.bound
  setMaxPlaytime(time) {
    this.maxPlaytime = time
  }

  @action.bound
  setSort(sortBy) {
    this.sort = sortBy
  }

  @action.bound
  onFilterByNameChange(e) {
    this.filter = e.target.value
  }

  render() {
    const gameItems = this.gamesListItems(this.games)

    return (
      <Container fluid={false}>
        <Search onFilterByNameChange={this.onFilterByNameChange} filter={this.filter} />

        <Menu widths={5} compact size="tiny" fixed="top">
          <HomeMenu username={this.props.match.params.username} />

          <PlayerMenu playerCount={this.playerCount} setPlayerCount={this.setPlayerCount} />

          <PlaytimeMenu maxPlaytime={this.maxPlaytime} setMaxPlaytime={this.setMaxPlaytime} />

          <SortMenu sort={this.sort} setSort={this.setSort} />

          <ReloadMenu isLoading={this.GameStore.loading} onClick={() => this.load(true)} />
        </Menu>

        <div className={styles.collection}>
          {this.games.length > 0 && (
            <h3>
              {this.props.match.params.username}: Showing {gameItems.length.toLocaleString()} of{" "}
              {this.games.length.toLocaleString()} games
            </h3>
          )}

          {this.GameStore.error && <h3>{this.GameStore.error}</h3>}

          <Item.Group divided unstackable link>
            {gameItems}
          </Item.Group>
        </div>
      </Container>
    )
  }
}

export default Collection
