import React, { Component } from "react"
import { Item, Icon } from "semantic-ui-react"
import styles from "./GameItem.module.css"

export default class GameItem extends Component {
  onClick(game) {
    let win = window.open(game.url, "_blank")
    win.focus()
  }

  render() {
    const game = this.props.game
    return (
      <Item onClick={() => this.onClick(game)}>
        <Item.Image className={styles.thumbnail} src={game.thumbnail} />

        <Item.Content className={styles.content}>
          <Item.Header>{game.name}</Item.Header>

          <Item.Meta>
            {game.minPlayers !== game.maxPlayers && (
              <span>
                <Icon name="users" /> {game.minPlayers} - {game.maxPlayers} players
              </span>
            )}
            {game.minPlayers === game.maxPlayers && game.maxPlayers !== 0 && (
              <span>
                <Icon name="users" /> {game.minPlayers} players
              </span>
            )}
          </Item.Meta>

          <Item.Meta>
            {game.minPlaytime !== game.maxPlaytime && (
              <span>
                <Icon name="clock" /> {game.minPlaytime} - {game.maxPlaytime} mins
              </span>
            )}
            {game.minPlaytime === game.maxPlaytime && game.minPlaytime !== 0 && (
              <span>
                <Icon name="clock" /> {game.minPlaytime} mins
              </span>
            )}
          </Item.Meta>

          <Item.Meta>
            <span>
              <Icon name="star" /> {game.rating}
              {Number(game.rank) !== 999999 && (
                <span>
                  &nbsp;
                  <Icon name="chess queen" /> {Number(game.rank).toLocaleString()}
                </span>
              )}
            </span>
          </Item.Meta>
        </Item.Content>
      </Item>
    )
  }
}
