import React from "react"
import { Dropdown, Menu } from "semantic-ui-react"
import styles from "./Menu.module.css"

export default function SortMenu(props) {
  return (
    <Menu.Item>
      <Dropdown item text={props.sort} icon="sort" button className={styles.button}>
        <Dropdown.Menu>
          <Dropdown.Item text="Name" onClick={() => props.setSort("Name")} />
          <Dropdown.Item text="Rating" onClick={() => props.setSort("Rating")} />
          <Dropdown.Item text="Rank" onClick={() => props.setSort("Rank")} />
          <Dropdown.Item text="Random" onClick={() => props.setSort("Random")} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}
