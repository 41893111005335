import { observable, action, runInAction } from "mobx"
import { sleep, hash } from "../lib/Utils"
import Storage from "../lib/Storage"
import axios from "axios"
import Fuse from "fuse.js"

const proxyURL = "https://7g1ptxo5d1.execute-api.us-east-1.amazonaws.com/prod/bgg"

export default class GameStore {
  @observable loading = false
  @observable games = null
  @observable error = null
  fuse = null
  username = null

  constructor() {
    this.cache = new Storage("bgg")
    this.proxy = axios.create({ baseURL: proxyURL })
  }

  async loadGamesForUsername(username, forceReload = false) {
    console.log("Getting games for", username)
    runInAction(() => {
      this.error = null
      if (username !== this.username) {
        this.games = null
      }
      this.username = username
    })

    const cacheKey = `collections/${username}`
    const cacheItem = this.cache.get(cacheKey)
    if (cacheItem?.value) {
      this.games = cacheItem.value
      this.index()
      // don't bother reloading in the background more than once every 24 hours
      if (!forceReload && cacheItem.ageInSeconds < 60 * 60 * 24) {
        console.log(`Skipping game data reload, cached data is only ${cacheItem.ageInSeconds}s old`)
        return
      }
    }

    let data = await this.loadWithRetry("/collection", {
      username,
      stats: 1,
      own: 1,
      excludesubtype: "boardgameexpansion",
      version: 1,
    })
    if (data?.items) {
      this.setGamesFromData(data)
      this.index()
      this.cache.set(cacheKey, this.games, true)
      console.log(`Loaded ${this.games.length} games for ${username}`)
    } else {
      console.log("Failed to load data", data)
      runInAction(() => {
        this.error = data?.errors?.error?.message ?? "unknown error"
      })
    }
  }

  @action
  async loadWithRetry(path, params, maxRetries = 10) {
    this.loading = true
    let attempt = 1
    do {
      console.log(`Fetching from bgg, attempt ${attempt} of ${maxRetries + 1}...`)
      const response = await this.proxy.get(path, { params })
      if (response.status === 200) {
        runInAction(() => {
          this.loading = false
        })
        return response.data
      } else {
        console.log("Request failed with status", response.status, response.data)
        await sleep(attempt * 500)
      }
    } while (attempt++ <= maxRetries)
    runInAction(() => {
      this.loading = false
    })
    return null
  }

  @action index() {
    console.log(`Indexing ${this.games.length} games...`)
    this.fuse = new Fuse(this.games, { keys: ["name"], threshold: 0.3 })
  }

  @action
  setGamesFromData(data) {
    if (Array.isArray(data.items.item)) {
      this.games = data.items.item.map((item) => {
        return this.gameFromItem(item)
      })
    } else {
      this.games = [this.gameFromItem(data.items.item)]
    }
  }

  gameFromItem(item) {
    const game = {
      name: item.name.__text,
      thumbnail: item.thumbnail,
      objectId: item._objectid,
      type: item._objecttype,
      subtype: item._subtype,
      minPlayers: Number(item.stats._minplayers || 0),
      maxPlayers: Number(item.stats._maxplayers || 0),
      minPlaytime: Number(item.stats._minplaytime || 0),
      maxPlaytime: Number(item.stats._maxplaytime || 0),
      rating: Number(Number(item.stats.rating.average._value || 0).toFixed(1)),
      rank: Number(this.rankForItem(item) === "Not Ranked" ? "999999" : this.rankForItem(item)),
      url: `https://boardgamegeek.com/${item._subtype}/${item._objectid}/`,
      hash: hash(JSON.stringify(item)),
    }
    return game
  }

  rankForItem(item) {
    if (Array.isArray(item.stats.rating.ranks.rank)) {
      return item.stats.rating.ranks.rank.find((r) => {
        return r._name === "boardgame"
      })._value
    } else {
      return item.stats.rating.ranks.rank._value
    }
  }
}
