import { observable, action } from "mobx"
import Storage from "../lib/Storage"

export default class UiStore {
  @observable username = null

  constructor() {
    this.currentUserKey = "username"
    this.cache = new Storage("bgg")
    this.restore()
  }

  @action.bound restore() {
    const cacheItem = this.cache.get(this.currentUserKey)
    if (cacheItem?.value) {
      this.username = cacheItem.value
    }
  }

  @action.bound setUsername(username) {
    this.username = username
    this.cache.set(this.currentUserKey, username)
  }
}
