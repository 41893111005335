import React, { Component } from "react"
import { inject, observer } from "mobx-react"
import { Container, Button, Grid, Form, Segment, Message, Header } from "semantic-ui-react"
import { Link } from "react-router-dom"
import styles from "./Users.module.css"

@inject("uistore")
@observer
class Users extends Component {
  componentDidMount() {
    document.title = `BGG Collection Browser`
  }

  render() {
    return (
      <Container fluid={false} text className={styles.login}>
        <Header>BGG Collection Browser</Header>
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  placeholder="BGG username"
                  fluid
                  value={this.props.uistore.username || ""}
                  onChange={this.onUserChange}
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoFocus
                  name="username"
                />

                <Link to={`/users/${this.props.uistore.username}`}>
                  <Button fluid size="large">
                    View Collection
                  </Button>
                </Link>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>

        <Message>
          <Message.Header>About this site</Message.Header>
          <p>
            This site uses the <a href="https://boardgamegeek.com">Board Game Geek</a> XML API to
            allow you to browse your collection. The application is optimized for mobile use and
            makes extensive use of caching and background updating to be nice and fast after the
            initial load. Click or tap on any game to open it at Board Game Geek. Filters let you
            find a game in your collection quickly, by player count and play time. You can even sort
            the results randomly to help in choosing a game. Questions or comments? Send me an{" "}
            <a href="mailto:duane@duanefields.com">email</a>.
          </p>

          <p>Updated: 05/15/2023 08:28pm</p>

          <a href="https://www.buymeacoffee.com/dkf2112" target="_blank" rel="noopener noreferrer">
            <img
              className={styles.beerButton}
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
            />
          </a>
        </Message>
      </Container>
    )
  }

  onUserChange = (e) => {
    this.props.uistore.setUsername(e.target.value)
  }
}

export default Users
