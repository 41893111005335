import React from "react"
import { Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"
import Avatar from "components/Avatar"

export default function HomeMenu({ username }) {
  return (
    <Menu.Item>
      <Link to="/">
        <Avatar username={username} />
      </Link>
    </Menu.Item>
  )
}
