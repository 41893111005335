import React, { useEffect } from "react"
import { Provider } from "mobx-react"
import UiStore from "./stores/UiStore"
import { useLocation } from "react-router-dom"
import Routes from "./routes"
import ReactGA from "react-ga"

const uistore = new UiStore()

function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location])
}

function App() {
  usePageViews()
  return (
    <Provider uistore={uistore}>
      <Routes />
    </Provider>
  )
}

export default App
