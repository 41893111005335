import LZString from "lz-string/libs/lz-string.js"

const VERSION = 3
const byteSize = (str) => new Blob([str]).size / 1024

export default class Storage {
  constructor(bucket) {
    this.bucket = bucket
  }

  keyForName(name) {
    return `${this.bucket}/${name}`
  }

  get(name, maxAgeInSeconds = Number.MAX_VALUE) {
    const key = this.keyForName(name)
    let cacheItemString = localStorage.getItem(key)
    if (cacheItemString == null) {
      console.log("Found nothing in the cache for key", key)
      return null
    }
    try {
      const cacheItem = JSON.parse(cacheItemString)

      // ignore old cached versions, because the format may have changed
      if (cacheItem.version == null || cacheItem.version < VERSION) {
        console.log("Ignoring cached item with old version number", key)
        return null
      }

      // decompress cache values
      if (cacheItem.compressed) {
        cacheItem.value = LZString.decompress(cacheItem.value)
        if (cacheItem.value == null) {
          console.log("Ignoring cached item which failed to decompress", key)
          return null
        }
        cacheItem.value = JSON.parse(cacheItem.value)
      }

      // calculate the age
      let now = new Date().getTime()
      let timestamp = cacheItem.timestamp ?? 0
      cacheItem.ageInSeconds = (now - timestamp) / 1000
      if (cacheItem.ageInSeconds > maxAgeInSeconds) {
        console.log("Ignoring cached item which is too old", key)
        return null
      }

      return cacheItem
    } catch (err) {
      this.remove(name)
      return null
    }
  }

  set(name, value, compress = false) {
    const key = this.keyForName(name)
    const timestamp = new Date().getTime()
    if (compress) {
      const compressed = LZString.compress(JSON.stringify(value))
      console.log(`Compressed ${byteSize(JSON.stringify(value))}K to ${byteSize(compressed)}K`)
      value = compressed
    }
    const cacheItem = { value, timestamp, version: VERSION, compressed: compress }
    localStorage.setItem(key, JSON.stringify(cacheItem))
  }

  remove(name) {
    const key = this.keyForName(name)
    localStorage.removeItem(key)
  }

  reset() {
    const toBeDeleted = []
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.startsWith(`${this.bucket}/`)) {
        toBeDeleted.push(key)
      }
    }
    toBeDeleted.forEach((key) => localStorage.removeItem(key))
  }
}
