import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Collection from "./components/Collection"
import Users from "./components/Users"

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Users} />
      <Redirect exact path="/users" to="/" />
      <Route exact path="/users/:username?" component={Collection} />
    </Switch>
  )
}
