import axios from "axios"
import Storage from "lib/Storage"
import React from "react"
import { useEffect, useState } from "react"
import { Icon } from "semantic-ui-react"
import styles from "./Avatar.module.css"
import TestRenderer from "react-test-renderer"
const { act } = TestRenderer

const proxyURL = "https://7g1ptxo5d1.execute-api.us-east-1.amazonaws.com/prod/bgg"
const proxy = axios.create({ baseURL: proxyURL })
const cache = new Storage("bgg")

export default function Avatar({ username }) {
  const key = `avatar/${username}`
  const maxAgeInSeconds = 60 * 60 * 24 * 7
  const [imageURL, setImageURL] = useState(cache.get(key, maxAgeInSeconds)?.value)
  const [userLoaded, setUserLoaded] = useState(imageURL != null)

  useEffect(() => {
    const fetchImageURL = async () => {
      const params = { name: username }
      const response = await proxy.get("/user", { params })
      let avatar = response.data.user.avatarlink._value
      if (avatar !== "N/A") {
        act(() => {
          setImageURL(avatar)
        })
      } else {
        act(() => {
          setImageURL(null)
        })
      }
      cache.set(key, imageURL)
      act(() => {
        setUserLoaded(true)
      })
    }
    if (!userLoaded) {
      fetchImageURL()
    }
  })

  if (!userLoaded) {
    return null
  }

  if (imageURL == null) {
    return <Icon name="home" />
  }

  return <img className={styles.photo} alt={username} title={username} src={imageURL} />
}
