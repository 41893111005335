import React from "react"
import { Input } from "semantic-ui-react"
import styles from "components/Search.module.css"

export default function Search(props) {
  return (
    <Input
      icon="search"
      id="search"
      placeholder="Search by name..."
      fluid
      className={styles.search}
      onChange={props.onFilterByNameChange}
      value={props.filter}
      autoFocus={false}
      autoCapitalize="none"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
    />
  )
}
